import logo from "./Transparent_Image_1.png";
import "./App.css";

function App() {
  return (
    <div className="App">
      <header className="App-header">
        {/* <img src={logo} style={{ width: 500, height: 500 }} alt="logo" /> */}
        <img src={logo} style={{ width: 800, height: 800 }} />
        <p>Aja Studios first React App. We are pros now!</p>
      </header>
    </div>
  );
}

export default App;
